<template>
  <div class="import-wizard rounded">
    <v-card color="default" outlined class="elevation-0">
      <v-card-text>
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step > 1" step="1">
              Mandant auswählen
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              Datei Upload
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              Validierung der Daten
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 4" step="4">
              Import
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="5">
              Ende
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items class="elevation-0">
            <tenant-step />
            <file-upload-step />
            <validation-step />
            <import-step />
            <done-step />
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DoneStep from "./DoneStep.vue";
import FileUploadStep from "./FileUploadStep.vue";
import ImportStep from "./ImportStep.vue";
import TenantStep from "./TenantStep.vue";
import ValidationStep from "./ValidationStep.vue";

export default {
  components: {
    FileUploadStep,
    ValidationStep,
    ImportStep,
    DoneStep,
    TenantStep
  },
  computed: {
    ...mapState("importer", ["step"])
  }
};
</script>

<style lang="scss"></style>

<template>
  <div class="import mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">
            Import
          </h1>
        </v-col>
      </v-row>
      <import-wizard />
    </v-container>
  </div>
</template>

<script>
import ImportWizard from "@/components/import/ImportWizard.vue";

export default {
  components: { ImportWizard },
  name: "import"
};
</script>

<template>
  <div class="validation-step">
    <v-stepper-content step="3" class="pt-0">
      <v-row style="height:16px;" class="mb-3">
        <v-col v-if="loading">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <p>Bitte kontrollieren Sie die Einträge:</p>
      <v-card class="py-2" elevation="0" style="overflow-x: scroll;">
        <table class="validation-table">
          <tr>
            <th></th>
            <th v-for="(col, colIndex) in validationHeaders" :key="colIndex">
              {{ col }}
            </th>
          </tr>
          <tr v-for="(row, rowIndex) in validationData" :key="rowIndex">
            <td>
              <v-icon v-if="validateRow(row)" color="success">
                check
              </v-icon>
              <v-icon v-else color="error">close</v-icon>
            </td>
            <td
              v-for="(col, colIndex) in row"
              :key="colIndex"
              :class="
                col.valid
                  ? 'grey lighten-2 body-2 px-2 py-1'
                  : 'error body-2 px-2 py-1 white--text'
              "
            >
              <input v-model="col.value" class="validation-field" />
            </td>
          </tr>
        </table>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text large @click="prevStep()">
          Zurück
        </v-btn>
        <v-btn
          color="primary"
          large
          @click="validate()"
          class="elevation-0"
          :disabled="isValid && !isDirty"
        >
          Validieren
        </v-btn>
        <v-btn
          color="primary"
          large
          @click="nextStep()"
          class="elevation-0"
          :disabled="!isValid || isDirty"
        >
          Weiter
        </v-btn>
      </v-card-actions>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    loading: false,
    isDirty: false
  }),

  computed: {
    ...mapState("importer", [
      "step",
      "validationHeaders",
      "validationData",
      "isValid"
    ])
  },

  watch: {
    validationData: {
      handler(value) {
        if (this.step === 3) {
          this.isDirty = true;
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapMutations("importer", [
      "nextStep",
      "prevStep",
      "setValidationHeaders",
      "setValidationData",
      "setIsValid"
    ]),

    /**
     * Check if row is valid.
     */
    validateRow(row) {
      return !Object.values(row).find(col => !col.valid);
    },

    /**
     * Upload file to server.
     */
    validate() {
      this.loading = true;
      let data = this.validationData.map(row => {
        return Object.values(row).map(column => column.value);
      });
      this.$api.http
        .post(`backoffice/import/validate`, { data: data })
        .then(response => {
          this.loading = false;
          this.setValidationData(response.data.data);
          this.setIsValid(response.data.valid);
          this.$nextTick(() => {
            this.isDirty = false;
          });
        })
        .catch(error => {
          this.loading = false;
          this.isDirty = false;
          this.$toast.error(
            "Es gab einen Fehler bei der Validierung der Daten."
          );
        });
    }
  }
};
</script>

<style lang="scss">
.validation-step {
  table.validation-table {
    th {
      white-space: nowrap;
      text-align: left;
    }

    td {
      white-space: nowrap;
      padding: 0 !important;

      input.validation-field {
        border: none;
        outline: none;
        padding: 2px 4px;

        &:focus {
          background-color: #f3f3f3;
        }
      }
    }
  }
}
</style>

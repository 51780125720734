<template>
  <div class="import-step">
    <v-stepper-content step="4">
      <v-row>
        <v-col cols="12">
          <v-card elevation="0" color="grey lighten-3">
            <v-card-text class="text-subtitle-1">
              <strong>Mandant: </strong> {{ tenantName }}<br />
              <strong>Zu importierende Personen: </strong>
              {{ validationData.length }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="ma-4">
          <h2>Auswahl SIPORT Profile</h2>
          <v-row>
            <v-col v-for="i in [0, 1, 2, 3]" :key="i">
              <v-checkbox
                v-for="item in getProfilesForColumn(i)"
                :key="item.profile"
                v-model="selectedSiportProfiles"
                :label="`${item.profile} - ${item.name}`"
                color="primary"
                :value="item.profile"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="height:16px;" class="mb-3">
        <v-col v-if="loading">
          <v-progress-linear
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn text large @click="prevStep()">
          Zurück
        </v-btn>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="mails"
          :label="`Einladungen per E-Mail verschicken`"
          color="primary"
          :value="true"
          class="mr-4"
        ></v-checkbox>
        <v-btn
          color="primary"
          large
          @click="importData()"
          class="elevation-0"
          :disabled="loading"
        >
          Importieren
        </v-btn>
      </v-card-actions>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    loading: false
  }),

  computed: {
    ...mapState("importer", [
      "step",
      "validationData",
      "tenantId",
      "tenantName",
      "siportProfiles",
      "sendMails"
    ]),
    ...mapState("app", ["configs"]),

    availableSiportProfiles() {
      return this.configs.siport_profiles;
    },

    mails: {
      get() {
        return this.sendMails;
      },
      set(value) {
        this.setSendMails(value);
      }
    },

    selectedSiportProfiles: {
      get() {
        return this.siportProfiles;
      },
      set(value) {
        this.setSiportProfiles(value);
      }
    }
  },

  methods: {
    ...mapMutations("importer", [
      "nextStep",
      "prevStep",
      "setSiportProfiles",
      "setSendMails"
    ]),

    /**
     * Get profiles for a specific column.
     */
    getProfilesForColumn(i) {
      const numPerColumn = Math.ceil(
        Object.values(this.availableSiportProfiles).length / 4
      );
      return Object.values(this.availableSiportProfiles)
        .sort((a, b) => {
          return a.name < b.name ? -1 : 0;
        })
        .filter((k, index) => Math.floor(index / numPerColumn) === i);
    },

    /**
     * Import data.
     */
    importData() {
      this.loading = true;
      let data = this.validationData.map(row => {
        return Object.values(row).map(column => column.value);
      });
      this.$api.http
        .post(`backoffice/import/store`, {
          tenant_id: this.tenantId,
          data: data,
          siport_profiles: this.siportProfiles,
          send_mails: !!this.sendMails
        })
        .then(response => {
          this.loading = false;
          this.$nextTick(() => {
            this.nextStep();
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toast.error("Es gab einen Fehler beim importieren der Daten.");
        });
    }
  }
};
</script>

<style></style>

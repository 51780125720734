<template>
  <div class="done-step">
    <v-stepper-content step="5">
      <h2 class="text-center my-8">Die Liste wurde erfolgreich importiert.</h2>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" large @click="reset()" class="elevation-0">
          Abschliessen
        </v-btn>
      </v-card-actions>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  computed: {
    ...mapState("importer", ["step"])
  },

  methods: {
    ...mapMutations("importer", ["reset"])
  }
};
</script>

<style></style>

<template>
  <div class="done-step">
    <v-stepper-content step="1">
      <v-card class="mb-6 py-6 bg-transparent" elevation="0">
        <v-row>
          <v-col cols="4" offset="4">
            <tooltip-button
              icon="add"
              text="Mandant erfassen"
              :dark="false"
              color="default"
              @click="$refs.tenantDialog.open = true"
              classNames="ml-3 float-right"
              position="top"
            />
            <p class="py-4">
              Bitte wählen Sie zunächst den Mandanten aus oder erfassen Sie
              einen neuen.
            </p>
            <v-select
              outlined
              dense
              :items="tenants"
              v-model="tenant"
              label="Mandant"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          large
          @click="nextStep()"
          class="elevation-0"
          :disabled="!tenantId"
        >
          Weiter
        </v-btn>
      </v-card-actions>
      <tenant-dialog ref="tenantDialog" />
    </v-stepper-content>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TenantDialog from "@/components/dialogs/TenantDialog";
import TooltipButton from "@/components/forms/TooltipButton";

export default {
  components: {
    TenantDialog,
    TooltipButton
  },

  data: () => ({
    tenants: []
  }),

  computed: {
    ...mapState("importer", ["step", "tenantId"]),

    tenant: {
      get() {
        return this.tenantId;
      },
      set(value) {
        this.setTenantId(value);
        this.setTenantName(this.tenants.find(t => t.value === value).text);
      }
    }
  },

  created() {
    this.fetchTenants();
  },

  methods: {
    ...mapMutations("importer", ["nextStep", "setTenantId", "setTenantName"]),

    /**
     * Fetch tenants from server
     */
    fetchTenants() {
      this.$api.http
        .get(`backoffice/tenants?_per_page=99999`)
        .then(response => {
          this.tenants = response.data.data.map(tenant => {
            return {
              text: `${tenant.name}`,
              value: tenant.id
            };
          });
        });
    }
  }
};
</script>

<style></style>

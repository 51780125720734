<template>
  <div class="file-upload-step">
    <v-stepper-content step="2" class="pt-0">
      <v-card elevation="0">
        <v-card-text>
          <v-row style="height:16px;" class="mb-3">
            <v-col v-if="loading">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <p class="font-weight-bold">
                Formatieren Sie Ihre Importdaten ordnungsgemäss auf Ihrem
                Computer, um zu verhindern, dass Fehler beim Importieren der
                Daten auftreten.
              </p>
              <ul>
                <li>
                  Die Importdatei muss eine CSV- oder XLSX-Datei sein.
                </li>
                <li>Die Importdatei sollte nur über ein Blatt verfügen.</li>
                <li>
                  Die Importdatei sollte weniger als 250.000 Zeilen und 1.000
                  Spalten enthalten.
                </li>
                <li>Die Importdatei sollte kleiner als 150 MB sein.</li>
                <li>Fügen Sie in Ihrer Datei eine Kopfzeile hinzu</li>
              </ul>
            </v-col>
            <v-col cols="6" offset="1">
              <v-file-input
                v-model="uploadFile"
                class="mb-2"
                show-size
                label="Datei"
                prepend-icon=""
                append-icon="attach_file"
                outlined
              ></v-file-input>
              <h4 class="mb-2 black--text">
                Beispiel-Datei herunterladen:
              </h4>
              <p>
                Laden Sie eine Vorlage herunter, die Sie als Leitfaden verwenden
                können, um die eigene Benutzerliste für den Import zu erstellen.
              </p>
              <v-btn class="elevation-0" @click="downloadExample('csv')">
                CSV
              </v-btn>
              <v-btn class="elevation-0 ml-3" @click="downloadExample('xlsx')">
                Excel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-3 pa-0">
          <v-spacer></v-spacer>
          <v-btn color="default" large @click="prevStep()" class="elevation-0">
            Zurück
          </v-btn>
          <v-btn
            color="primary"
            large
            @click="upload()"
            class="elevation-0"
            :disabled="!uploadFile"
          >
            Hochladen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    loading: false
  }),

  computed: {
    ...mapState("importer", ["step", "file"]),

    uploadFile: {
      get() {
        return this.file;
      },

      set(value) {
        this.setFile(value);
      }
    }
  },

  methods: {
    ...mapMutations("importer", [
      "nextStep",
      "prevStep",
      "setFile",
      "setValidationHeaders",
      "setValidationData",
      "setIsValid"
    ]),

    /**
     * Download example files.
     */
    downloadExample(fileType) {
      this.$api.http
        .post(
          "backoffice/export/example",
          { "content-type": fileType },
          {
            responseType: "blob"
          }
        )
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "Example." + fileType);
          document.body.appendChild(fileLink);

          fileLink.click();
        });
    },

    /**
     * Upload file to server.
     */
    upload() {
      this.loading = true;
      let formData = new FormData();
      formData.append("file", this.uploadFile);
      this.$api.http
        .post(`backoffice/import/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.loading = false;
          this.setValidationHeaders(response.data.headers);
          this.setValidationData(response.data.data);
          this.setIsValid(response.data.valid);
          this.$nextTick(() => {
            this.nextStep();
          });
        })
        .catch(error => {
          this.loading = false;
          this.$toast.error(
            "Es gab ein Fehler beim Upload der Datei. Bitte prüfe nochmals die Formattierung der Spalten."
          );
        });
    }
  }
};
</script>

<style></style>
